import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {toast} from 'react-toastify';
import moment from "moment";
import { dashboard } from "../../actions/userAction";
import $ from 'jquery';

const Dashboard = () => {

  const dispatch = useDispatch();

    const [dashboardList, setDashboardList] = useState([]);

    useEffect(() => {
        dispatch(dashboard());
    }, [dispatch])

    const dashboardDtl = useSelector((state) => state.dashboardData.dashboardData)
    useEffect(() => {
        if (dashboardDtl && dashboardDtl?.data && dashboardDtl?.data?.status === true) {
            setDashboardList(dashboardDtl?.data?.data);
        }
        if (dashboardDtl && dashboardDtl?.data && dashboardDtl?.data?.status === false) {
            toast.error(dashboardDtl?.data?.message, {theme: "dark"});
        }
    }, [dashboardDtl]);

    // impelement datatable from jquery
    useEffect(() => {
        dashboardList && dashboardList.length > 0 && $(document).ready(function () {
            $('#myTable').DataTable({
                bDestroy: true, 
                pageLength: 10
            });
        });
    }, [dashboardList])

    return (
        <>
            <div className="dash-body">
                <div className="head-row d-block">
                    <h3>Dashboard</h3>
                    <span className="text-danger">Pre Reminder Order</span>    
                </div>
                
                <div className="table-responsive dash-table data_tableUs">
                    <table id="myTable" width="100%" border={0} cellSpacing={0} cellPadding={0}>
                        <thead>
                            <tr>
                                <th>Order Name</th>
                                <th>Date</th>
                                <th>Buyer</th>
                                <th>Seller</th>
                                <th>Total Quantity</th>
                                <th>Total Amount</th>
                                <th>Shipment Date</th>
                            </tr>
                        </thead>
                        <tbody>
                        {(dashboardList.length > 0) ? (
                            <>
                                {dashboardList.map((order, index) => (
                                    <tr key={index}>
                                      <td>{(order?.order_no) ? order?.order_no : '-'}</td>
                                      <td>{(order?.order_date) ? moment(order?.order_date).format('DD-MMM-YYYY') : '-'}</td>
                                      <td>{(order?.buyers?.name) ? order?.buyers?.name : '-'}</td>
                                      <td>{(order?.sellers?.name) ? order?.sellers?.name : '-'}</td>
                                      <td>{(order?.total_quantity) ? order?.total_quantity : '-'}</td>
                                      <td>{(order?.total_cnf) ? '$'+order?.total_cnf : '-'}</td>
                                      <td>{(order?.shipment_date) ? moment(order?.shipment_date).format('DD-MMM-YYYY') : '-'}</td>
                                  </tr>
                                ))}
                              </>
                          ): (
                              <tr>
                                  <td colSpan={7} align={'center'}> No order available </td>
                              </tr>
                          )}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    );

};
export default Dashboard
